import { Box } from "@mui/material";
import "@splidejs/react-splide/css";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

import Insurance from "./components/pages/Insurance/Insurance";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to={{pathname:"/santevet", search:window.location.search}} replace />,
    },
    {
      path: "/santevet",
      element: <Insurance />,
    },
  ]);

  return (
    <Box className={`App`}>
      <RouterProvider router={router} />
    </Box>
  );
};

export default App;
