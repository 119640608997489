import { Grid, Box } from "@mui/material";
import { ReactSVG } from "react-svg";
import Text from "../../atoms/Text/Text";
import Title from "../../atoms/Title/Title";

interface Props {
  image: string;
  title: string;
  text: JSX.Element;
}

const OfferDetails = ({ image, title, text }: Props) => (
  <Grid item container spacing={0} className="OfferDetails">
    <Grid item xs={3} className="item">
      <Box className="offerDetailImageContainer">
        <ReactSVG className="IconSVG" src={image} />
      </Box>
    </Grid>
    <Grid item xs={9} className="item">
      <Box className="offerDetailContent">
        <Title variant="h3">{title}</Title>
        <Text textAlign="left">{text}</Text>
      </Box>
    </Grid>
  </Grid>
);

export default OfferDetails;
