import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { apiSlice } from "../_api/api-slice";
import RatesReducer from "../rates/rates";
import UserReducer from "../user/user";

const combineSlices = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  rates: RatesReducer,
  user: UserReducer,
});

const store = configureStore({
  reducer: combineSlices,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export default store;
