import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Rate, RatesState } from "../../types/rtk/rates/rates";
import { apiSlice } from "../_api/api-slice";

export const rates = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getRates: build.query<Rate, void>({
      query: () => ({
        url: `rates`,
        method: "get",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetRatesQuery } = rates;

const initialState: RatesState = {
  chosen_rate: undefined,
};

const RatesSlice = createSlice({
  name: "rates",
  initialState: initialState,
  reducers: {
    setRates: (state, action: PayloadAction<RatesState>) => {
      state.chosen_rate = action.payload.chosen_rate;
    },
  },
});

export const { setRates } = RatesSlice.actions;
const RatesReducer = RatesSlice.reducer;
export default RatesReducer;
