import { Grid } from "@mui/material";

import OfferDetails from "../../molecules/OfferDetails/OfferDetails";
import bill from "../../../assets/Group 523.svg";
import seringe from "../../../assets/Group 524.svg";
import moneyPouch from "../../../assets/Group 525.svg";
import cross from "../../../assets/Group 526.svg";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  hidden_field?: Array<string>;
}

const OfferDetailsContainer = ({ hidden_field }: Props) => {
  const { t } = useTranslation();

  const details = [
    {
      bloc_id: "refund",
      icon: bill,
      title: t("santevet:OFFERS.BILL.TITLE"),
      text: (
        <Trans
          i18nKey={"santevet:OFFERS.BILL.TEXT"}
          components={{ span: <span /> }}
        />
      ),
    },
    {
      bloc_id: "prevention",
      icon: seringe,
      title: t("santevet:OFFERS.SERINGE.TITLE"),
      text: (
        <Trans
          i18nKey={"santevet:OFFERS.SERINGE.TEXT"}
          components={{ span: <span /> }}
        />
      ),
    },
    {
      bloc_id: "payvet",
      icon: moneyPouch,
      title: t("santevet:OFFERS.MONEY_PUSH.TITLE"),
      text: (
        <Trans
          i18nKey={"santevet:OFFERS.MONEY_PUSH.TEXT"}
          components={{ span: <span /> }}
        />
      ),
    },
    {
      bloc_id: "emergency",
      icon: cross,
      title: t("santevet:OFFERS.CROSS.TITLE"),
      text: (
        <Trans
          i18nKey={"santevet:OFFERS.CROSS.TEXT"}
          components={{ span: <span /> }}
        />
      ),
    },
  ];

  return (
    <Grid container spacing={3} className="OfferDetailsContainer">
      {details.map((detail, index) => (
        hidden_field?.includes(detail.bloc_id) ? null : <OfferDetails
          key={index}
          image={detail.icon}
          title={detail.title}
          text={detail.text}
        />
      ))}
    </Grid>
  );
};

export default OfferDetailsContainer;
