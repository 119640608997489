import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { ReactSVG } from "react-svg";

import Title from "../../atoms/Title/Title";
import Text from "../../atoms/Text/Text";
import AdvantagesContainer from "../../molecules/AdvantagesContainer/AdvantagesContainer";
import OfferDetailsContainer from "../../organisms/OfferDetailsContainer/OfferDetailsContainer";
import SpinnerLoader from "../../atoms/SpinnerLoader/SpinnerLoader";
import { Rate } from "../../../types/rtk/rates/rates";
import Logo from "../../../assets/logo.svg";

interface Props {
  rates?: Rate;
  price?: string | null;
}

const MainContent = ({ rates, price }: Props) => {
  const { t } = useTranslation();
  const [animalName, setAnimalName] = useState("");
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (rates) {
      setAnimalName(rates.animal_name);
      const lowest = rates.rates.reduce((acc, loc) =>
        acc.amount < loc.amount ? acc : loc
      ).amount;
      setAmount(lowest);
    }
  }, [rates]);

  return (
    <Box className="MainContent">
      {rates ? (
        <>
          <Box className="headerContent">
            <Box className="logo">
              <ReactSVG src={Logo} />
            </Box>
            <Title
              variant="h3"
              children={t(
                price === "true"
                  ? "santevet:TITLE"
                  : "santevet:TITLE_WITHOUT_PRICE",
                {
                  name: animalName,
                  amount: price === "true" && amount,
                }
              )}
            />
            <Text children={t("santevet:SUBTITLE")} />
          </Box>
          <AdvantagesContainer />
          <Text
            className="bold"
            textAlign="left"
            children={t("santevet:OFFERS.TITLE")}
          />
          <OfferDetailsContainer hidden_field={rates.hidden_field} />
        </>
      ) : (
        <SpinnerLoader />
      )}
    </Box>
  );
};

export default MainContent;
