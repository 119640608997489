import { CircularProgress, Box } from "@mui/material";
import { CSSProperties } from "react";

interface Props {
  size?: number;
  style?: CSSProperties;
}

const SpinnerLoader = ({ size = 30, style }: Props) => {
  return (
    <Box style={style} className="SpinnerLoader">
      <CircularProgress
        style={{ height: `${size / 10}rem`, width: `${size / 10}rem` }}
      />
    </Box>
  );
};

export default SpinnerLoader;
